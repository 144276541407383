

function Array_map_gallary(input_array, handle_click, print_page_path) {
  return input_array.map((item) => {
    return (
      <div className="gallary_height_buffer" key={item.id}>
        <div  className="gallary_item" key={item.id}>
          <span id={item.id} onClick={() => handle_click(item.id)} className="gallary_item_img_link" >
            <img className="gallary_item_img" src={'assets/images/prints/thumbnails/' + item.picture} ></img>
            <span className='gallary_item_info'>
              <span to={print_page_path+item.id} className="gallary_item_name">{item.name}</span>
            </span>
          </span>
        </div>
      </div>
    );
  })
}

export default Array_map_gallary
