import React from 'react-dom'
import './about.css'

function About() {
  return (
    <div className='about'>
      <img className='about-img' src="assets/images/Chris.png" alt="Chris Paul" />
      <div className='about-text'>
        <p align="left">About the artist</p>
        <p align="left">Chris Paul is a Coast Salish artist who is gaining international acclaim for his limited edition giclee prints, cedar panels, and glass sculptures.</p>
        <p align="left">Born near Victoria, British Columbia in 1969 as a member of Tsartlip nation, Chris was immersed in Coast Salish art as a child. Before the age of thirty, Chris had completed one year of intensive training at ‘Ksan, the Gitanmaax School of Northwest Coast Art, and had completed a two year apprenticeship under celebrated Tsimshian artist, Roy Henry Vickers.</p>
        <p align="left">In 2007, Chris completed a major commission for the Sidney Pier Hotel and Spa. This project resulted in the creation of three large scale etched glass panels for the lobby of the hotel, as well as smaller panels placed in each of the hotel’s 55 rooms.</p>
        <p align="left">Chris has also gained exposure through the hit television series Grey’s Anatomy, in which a number of his prints have been featured. Chris’ pieces often reflect flora, fauna and mythologies associated with his culture and his home on Vancouver Island.</p>
      </div>
    </div>
  )
}

export default About
