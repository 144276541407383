import { Component } from 'react'
import get_env from './env.jsx'
import Form from './form.jsx'
import Rate_select from './rate_select.jsx'
import './checkout.css'
import { calculate_tax, calculate_subtotal, calculate_total, get_rate } from './checkout_util.jsx'
import data from './gallary_items.json'

class Checkout extends Component {
  calculate_tax = calculate_tax
  calculate_total = calculate_total
  calculate_subtotal = calculate_subtotal
  get_rate = get_rate

  constructor() {
    super()
    this.state = {
      order_data: {},
      data_collect: '',
      address: '',
      summary: '',
      shipping_rate_id: '',
      shipping_rate: '',
      shipping_rates: '',
      rate: 'N/A',
      tax: 0,
      sub_total: 0,
      total: 'N/A',
      display_total: ''
    }
  }

  render() {
    return (
      <div className='checkout'>
        <div className='data_collect'>
          {this.state.data_collect}
        </div>
        <div className='summary'>
          <h1>Summary</h1>
          <div className='orders_list'>
            {this.state.summary}
          </div>
          <div className='summary_details'>
            <div>
              <h2>Sub total: </h2>
              ${this.state.sub_total/100}
            </div>
          <hr className='summary_breaks'/>
            <div>
              <h2>Shipping </h2>
              {this.state.rate}
            </div>
          <hr className='summary_breaks'/>
            <div>
              <h2>Tax </h2>
              HST/GST: {this.state.tax}
            </div>
          <hr className='summary_breaks'/>
            <div>
              <h2>Total: </h2>
              ${this.state.total}
            </div>
          </div>
        </div>
      </div>
    )
  }

  make_summary(items) {
    return (
      <div className='summary_cart_list'>
        {items.map((order_data_item) => {
          const item = data.items.find((item) => item.id == order_data_item.id)
          return (
            <div className='summary_cart_item' key={item.id}>
              <img className='summary_cart_img' src={"assets/images/" + item.picture}/>
              <span className='summary_cart_text'>
                <span className='summary_cart_name'>{item.name}</span>
                <span className='summary_cart_price'>${item.price/100}</span>
              </span>
            </div>
          )
        })}
      </div>
    )
  }

  componentDidMount() {
    let order_data = {}
    order_data.items = JSON.parse(localStorage.getItem('cart'))

    this.setState({
      data_collect: (
        <Form handle_change={this.handle_address_change} handle_address_submit={this.handle_address_submit}/>
      ),
      order_data: order_data,
      summary: this.make_summary(order_data.items),
      sub_total: this.calculate_subtotal(order_data, null),
      total: this.calculate_total(order_data, null),
      tax: this.calculate_tax(order_data, null)
    })
  }

  start_summary() {
    const env = get_env()
    const query_string = window.location.search
    const url_params = new URLSearchParams(query_string)
    const order_id = url_params.get('order_id')

    fetch(env.BACK_END_URL + "/get-order-information", {
      method: "POST",
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        order_id: order_id
      }),
    })
      .then(res => {
        if(res.ok)
          return res.json()
        else
          return res.json().then(json => Promise.reject(json))

      })
      .then((order_data) => {
        order_data.shipment.rates.sort((a, b) => a.amount - b.amount)
        this.setState({
          order_data:order_data,
          rate: this.get_rate(order_data, order_data.shipment.rates[0].object_id),
        })
      })
      .catch(e => {
        console.error(e.error)
      })
  }

  handle_address_change = (e) => {
    this.setState({order_data: { ...this.state.order_data, [ e.target.name ]: e.target.value }})
  }

  handle_address_submit = (e) => {
    e.preventDefault()
    const env = get_env()
    const query_string = window.location.search
    const url_params = new URLSearchParams(query_string)
    const product_id = url_params.get('product_id')

    fetch(env.BACK_END_URL + "/create-order", {
      method: "POST",
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        name: this.state.order_data.name,
        email: this.state.order_data.email,
        street_address: this.state.order_data.street_address,
        postal_code: this.state.order_data.postal_code,
        city: this.state.order_data.city,
        state_or_provence: this.state.order_data.state_or_provence,
        country: this.state.order_data.country,
        items: this.state.order_data.items,
      }),
    })
      .then(res => {
        if(res.ok)
          return res.json()
        else
          return res.json().then(json => Promise.reject(json))

      }).then(({ order_data }) => {
        //FIXME: make sure this also checks if its valid instead of just complete
        // order_data.shipment.address_to.is_complete == true && order_data.shipment.address_to.is_valid == true ) {
        order_data.shipment.rates.sort((a, b) => a.amount - b.amount)
        if(order_data.shipment.address_to.is_complete === true) {
          this.setState({
            order_data: order_data,
            data_collect: (
              <Rate_select
                rates={order_data.shipment.rates}
                handle_rate_change={this.handle_rate_change}
                handle_submit={this.handle_rate_submit}
                address={{
                  name: order_data.name,
                  email: order_data.email,
                  street_address: order_data.street_address,
                  postal_code: order_data.postal_code,
                  city: order_data.city,
                  state_or_provence: order_data.state_or_provence,
                  country: order_data.country
                }}
            />
            ),
            shipping_rate_id: order_data.shipment.rates[0].object_id,
            rate: this.get_rate(order_data, order_data.shipment.rates.find(rate => rate.object_id === order_data.shipment.rates[0].object_id).object_id),
            sub_total: this.calculate_subtotal(order_data, order_data.shipment.rates[0].object_id),
            total: this.calculate_total(order_data, order_data.shipment.rates[0].object_id),
            tax: this.calculate_tax(order_data, order_data.shipment.rates[0].object_id)
          })
        } else if(order_data.shipment.address_to.validation_results.messages) {
          // set_messeges(order.address.validation_results.messages.text)
        }
      })
      .catch(e => {
        console.error(e.error)
      })
  }

  handle_rate_submit = (e) => {
    const env = get_env()
    e.preventDefault()

    fetch(env.BACK_END_URL + "/create-checkout-session", {
      method: "POST",
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        order_id: this.state.order_data._id,
        shipping_rate_id: this.state.shipping_rate_id,
      }),
    })
      .then(res => {
        if(res.ok)
          return res.json()
        else
          return res.json().then(json => Promise.reject(json))
      }).then(({ url }) => {
        window.location = url
      })
      .catch(e => {
        console.error(e.error)
      })


  }

  handle_rate_change = (e, rates)=>{
    this.setState({
      shipping_rate_id: e.target.value,
      rate: this.get_rate(this.state.order_data, rates.find(rate => rate.object_id === e.target.value).object_id),
      sub_total: this.calculate_subtotal(this.state.order_data, e.target.value),
      tax: this.calculate_tax(this.state.order_data, e.target.value),
      total: this.calculate_total(this.state.order_data, e.target.value)
    })
  }

}

export default Checkout
