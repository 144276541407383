import React, { Component } from 'react'
import './rate_select.css'

class Rate_select extends Component {
  constructor(props) {
    super()
    this.state = {
      props: props,
      shipment_rates: '',
      address: '',
    }
  }

  componentDidMount() {
    // <option value={rate.object_id}>{rate.provider} {rate.servicelevel.name}: ${rate.amount}</option>
    this.setState({
      shipment_rates: (
        <div className="rate_select">
          {this.state.props.rates.map((rate, i) => {
            return (
              <div key={rate.object_id}>
                <label htmlFor={rate.object_id}>
                  <input type='radio' name='rates' id={rate.object_id} value={rate.object_id} onChange={(e) => this.props.handle_rate_change(e, this.state.props.rates)}/>
                  {rate.provider} {rate.servicelevel.name}: ${rate.amount}
                </label>
              </div>
            )
          })}
        </div>
      ),
      address: (
        <ul>
          <li><span className='rate_address_list_label'>Name:</span> {this.props.address.name}</li>
          <li><span className='rate_address_list_label'>Email:</span> {this.props.address.email}</li>
          <li><span className='rate_address_list_label'>Street:</span> {this.props.address.street_address}</li>
          <li><span className='rate_address_list_label'>Postal Code:</span> {this.props.address.postal_code}</li>
          <li><span className='rate_address_list_label'>City:</span> {this.props.address.city}</li>
          <li><span className='rate_address_list_label'>Country:</span> {this.props.address.country}</li>
        </ul>
      )
    })
  }

  handle_submit = (e) => {
    e.preventDefault()
    this.props.handle_submit(e)
  }

  render() {
  return (
    <div className="shipping_rates">
      <div className='checkout_address'>
        <h1>Address</h1>
        {this.state.address}
      </div>
      <form className='shipping_select' onSubmit={this.handle_submit}>
        <h1 className='rates_header'>Shipping Rates</h1>
        {this.state.shipment_rates}
        <button type='submit'>submit</button>
      </form>
    </div>
  )
  }
}

export default Rate_select
