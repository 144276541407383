import React from 'react-dom'
import './about.css'
import About from './About.jsx'
import RandomGallary from './random_gallary.jsx'

function AboutPage() {
  return (
    <div>
      <About />
      <RandomGallary />
    </div>
  )
}

export default AboutPage
