import RandomGallary from './random_gallary.jsx'
import About from './About.jsx'
import { Link } from 'react-router-dom'

function LandingPage(props) {
  return (
    <div>
      <div className='landingpage-content'>
        <Link className='landingpage-button' to='/gallery'> <span>Gallery</span> </Link>
      </div>
      <About className='landingpage-about'/>
      <RandomGallary handle_click={props.handle_product_click}/>
    </div>
  )
}

export default LandingPage
