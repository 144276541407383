import './gallary.css'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons'
import gallary_data from './gallary_items.json';
import { useState } from 'react';
import Array_map_gallary from './array_map_gallary.jsx'
const data = gallary_data

function Gallary(props) {
  const print_page_path = '/print-page?product_id='
  const gallary_data_length = data.items.length

  let [current_page, set_current_page] = useState(0)
  const page_length = 12
  let selected = data.items.slice(current_page*page_length, current_page*page_length+page_length);

  const handle_next_page = () => {
    if(current_page < Math.ceil(gallary_data_length/page_length)-1)
      set_current_page(++current_page)
  }

  const handle_previous_page = () => {
    if(current_page > 0)
      set_current_page(--current_page)
  }

  const create_page_buttons = () => {
    let buttons = []
    for(let i = 0; i < Math.ceil(gallary_data_length/page_length); i++) {
      let class_name = 'pagination_button'

      if(i === current_page)
        class_name = class_name + ' ' + 'pagination_button_selected'

      buttons.push(
        <button key={i} className={class_name} onClick={() => set_current_page(i)}>{i+1}</button>
      )
    }

    return buttons
  }

  function handle_click(item_id) {
    props.handle_product_click(item_id)
  }

  return (
    <div>
      <h1 className='gallary_tital'>Gallery</h1>
      <p className='gallary_header_text'>If you would like to buy anything contact Chris via the commisions page.</p>
      <div className='pagination_content'>
        <span className='pagination_buttons'>
          <button className='pagination_button' onClick={handle_previous_page}> <FontAwesomeIcon icon={faArrowLeft} /> </button>
          {create_page_buttons()}
          <button className='pagination_button' onClick={handle_next_page}> <FontAwesomeIcon icon={faArrowRight} /></button>
        </span>
      </div>
      <div  className="gallary_content">
        {Array_map_gallary(selected, handle_click, print_page_path)}
      </div>
      <div className='pagination_content'>
        <span className='pagination_buttons'>
          <button className='pagination_button' onClick={handle_previous_page}> <FontAwesomeIcon icon={faArrowLeft} /> </button>
          {create_page_buttons()}
          <button className='pagination_button' onClick={handle_next_page}> <FontAwesomeIcon icon={faArrowRight} /></button>
        </span>
      </div>
    </div>
  );
}

export default Gallary
