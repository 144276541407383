import './footer.css'

import { Link } from "react-router-dom"


function Footer(props) {
  return (
    <div className='footer'>
      {props.makeNavList(props)}
    </div>
  )
}

export default Footer
