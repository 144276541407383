import { Link } from 'react-router-dom'
import './navbar.css'

function Navbar(props){
  return (
    <header className='navbar'>
      <nav className="navbar-content">
        <Link className='navbar-logo' to='/'>
          <img className='' src='assets/images/logo.png'/>
        </Link>
        <span className="navbar-list">
          {props.makeNavList(props)}
        </span>
      </nav>
    </header>
  );
}

export default Navbar
