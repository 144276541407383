import './gallary.css'
import { Link } from 'react-router-dom'
import Array_map_gallary from './array_map_gallary.jsx'
import gallary_data from './gallary_items.json';
const data = gallary_data

function RandomGallary(props) {
  const print_page_path = '/print-page?product_id='
  let shuffled = [...data.items]
  shuffled = shuffled.sort(() => 0.5 - Math.random());
  let selected = shuffled.slice(0, 3);

  const set_update = props.set_update;

  return (
    <div  className="gallary_content">
      {Array_map_gallary(selected, props.handle_click, print_page_path)}
    </div>
  );
}

export default RandomGallary
