import './form.css'

function Form(props) {
  const parent_handle_submit = props.handle_address_submit
  const handle_change = props.handle_change
  const handle_submit = (e) => {
    e.preventDefault()
    parent_handle_submit(e)
  }

  return (
    <form className='address_collect' onSubmit={handle_submit} method="POST" id="order_form">
      <div className='address_header'>
        <h1>Shipping Address</h1>
      </div>
      <div className='address_name'>
        <label>
          Order Name
        </label>
        <input
          type="text"
          name='name'
          onChange={e => handle_change(e)}
          required
        />
      </div>
      <div className='address_email'>
        <label>
          Email
        </label>
        <input
          type="email"
          name='email'
          onChange={e => handle_change(e)}
          required
        />
      </div>
      <div className='address_street_address'>
        <label>
          Street Address
        </label>
        <input
          type="text"
          name='street_address'
          onChange={e => handle_change(e)}
          required
        />
      </div>
      <div className='address_postal'>
        <label>
          Postal Code
        </label>
        <input
          type="text"
          name='postal_code'
          onChange={e => handle_change(e)}
          required
        />
      </div>
      <div className='address_city'>
        <label>
          City
        </label>
        <input
          type="text"
          name='city'
          onChange={e => handle_change(e)}
          required
        />
      </div>
      <div className='address_provence'>
        <label>
          State Provence
        </label>
        <input
          type="text"
          name='state_or_provence'
          onChange={e => handle_change(e)}
        />
      </div>
      <div className='address_country'>
        <label>
          Country
        </label>
        <input
          type="text"
          name='country'
          onChange={e => handle_change(e)}
          required
        />
      </div>
      <div className='address_button'>
        <button type="submit">
          submit
        </button>
      </div>
      <div className="address_spacer"/>
      <div className="address_spacer2"/>
    </form>
  )
}
// <p>{messages}</p>

export default Form
