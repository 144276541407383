import './contact-form.css'
import { Component } from 'react';


class ContactsForm extends Component {
  constructor() {
    super()
    this.state = {
      email: '',
      name: '',
      subject: '',
      message: '',
      submit_message: 'submit'
    }
  }

  send_email = async (data) => {
    await fetch(process.env.REACT_APP_SERVER_URL + "/send-email", {
      method: "POST",
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        data: data
      }),
    })
      .then(res => {
        if(res.ok)
          return res.json()
        else
          return res.json().then(json => Promise.reject(json))

      })
      .then(res_json => {
        console.log(res_json)
      })
      .catch(e => {
        console.error(e.error)
      })
  }

  FormSubmit = (e) => {
    e.preventDefault()
    document.getElementsByName('contact-form')[0].reset()
    this.setState({submit_message: 'Thank You!'})
    this.send_email(this.state)
  }

  render() {
    return (
      <div>
        <h1>Commision Form</h1>
        <p>Please give me a short description of what you're looking for</p>
        <br/>
        <div className={"contact-card " + "contacts-" + this.props.class_name_prop}>
          <form name='contact-form' className='form-container' onSubmit={this.FormSubmit}>
            <div className='form-item-container email-area'>
              <label className='form-label'>Email</label>
              <br/>
              <input type='email' onChange={e => { this.setState({email: e.target.value}) }}/>
            </div>
            <div className='form-item-container name-area'>
              <label className='form-label'>Name</label>
              <br/>
              <input onChange={e => { this.setState({name: e.target.value}) }}/>
            </div>
            <div className='form-item-container subject-area'>
              <label className='form-label'>Subject</label>
              <br/>
              <input onChange={e => { this.setState({subject: e.target.value}) }}/>
            </div>
            <div className='form-item-container message-area'>
              <label className='form-label'>Message</label>
              <br/>
              <textarea className='form-textarea' onChange={e => { this.setState({message: e.target.value}) }}/>
            </div>
            <input type='submit' value={this.state.submit_message} />
          </form>
        </div>
      </div>
    )
  }
}

export default ContactsForm
