import './cart.css'
import data from './gallary_items.json'

function Cart(props) {
  const cart = JSON.parse(localStorage.getItem('cart'))

  return (
    <div className='cart_list'>
      {cart.map((item) => {
        let item_data = data.items.find((gallary_item) => gallary_item.id == item.id)
        return (
          <div onClick={() => props.handle_product_click(item_data.id)} key={item_data.id} className='cart_item'>
            <img src={"./assets/images/" + item_data.picture}/>

            <div className='cart_text_container'>
              <p className="cart_list_tital">{item_data.name}</p>
              <p className="cart_list_description">{item_data.description}</p>
            </div>
          </div>
        )
      })}
    </div>
  );
}

export default Cart
