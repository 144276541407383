import data from './gallary_items.json'

function calculate_subtotal(order_data, rate_id) {
  let sub_total = 0

  order_data.items.map((order_data_item) => {
    const item = data.items.find((item) => item.id == order_data_item.id)
    sub_total += item.price
    return sub_total;
  })

  return sub_total
}

function calculate_tax(order_data, rate_id) {
  const sub_total = calculate_subtotal(order_data, rate_id)
  return Math.ceil((sub_total * 0.12))/100
}

function calculate_total(order_data, rate_id) {
  let total = 0
  total += calculate_subtotal(order_data, rate_id)

  if(rate_id){
    total += order_data.shipment.rates
      .find(rate => rate_id === rate.object_id)
      .amount*100
  } else {
    return 'N/A'
  }

  total += calculate_tax(order_data, rate_id)*100
  return total/100
}

function get_rate(order_data, rate_id) {
  return (
    <div>
      {(() => {
        const rate = order_data.shipment.rates.find(rate => rate.object_id === rate_id)
        return (
          <div>
            {rate.provider} {rate.servicelevel.name}: ${rate.amount}
          </div>
        )
      })()}
    </div>
  )
}

export {
  get_rate,
  calculate_tax,
  calculate_total,
  calculate_subtotal
}
