import data from './gallary_items.json'
import './print_page.css'

function PrintInfo(props) {
  const product_id = props.product_id;
  let item = data.items.find((item) => item.id === product_id)

  const handle_click = (e) => {
    let cart = JSON.parse(localStorage.getItem('cart'))

    let cart_item = cart.find((item => item.id === e.target.value))
    if(cart_item != null) {
      cart_item.quantity++
    } else {
      cart.push({'id': e.target.value, 'quantity': 1})
    }

    localStorage.setItem('cart', JSON.stringify(cart))
  }

  if(!item) {
    item = {
      picture: 'ThunderBird.png',
      name: 'unavalible',
      price: 0,
      description: ['id' + props.product_id],
    }
  } else if(item.description.length === 0) {
    item.description.push('Giclee print designed by Chris Paul.')
  }

  return (
    <div>
      <div className='print_page'>
        <div className='print_page_img_container'>
          <img src={'assets/images/prints/full/' + item.picture} />
        </div>
        <h1 className='print_info_tital'>{item.name}</h1>
        <div className='checkout_info'>
        </div>
        <div className='print_info_text'>
          {item.description.map((line, index) => {
            return (
              <p key={index}>
                {line}
              </p>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default PrintInfo
