import Contact from './Contact.jsx'
import ContactForm from './contact-form'
import RandomGallary from './random_gallary.jsx'
import CommisionsOld from './commisions_past.jsx'

function Commisions() {
  return (
    <div>
      <ContactForm />
      <CommisionsOld />
      <RandomGallary />
    </div>
  )
}
// <p className='commisions-blerb'>
// </p>

export default Commisions
