import './App.css';
import React, { Component, useEffect, useState } from 'react';

import {
  Routes,
  Route,
  Link,
  useLocation,
  useNavigate
} from "react-router-dom";

import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks
} from 'body-scroll-lock';


import LandingPage from './landing_page.jsx'
import Gallary from './gallary.jsx'
import AboutPage from './about_page.jsx'
import Commisions from './commisions.jsx'
import Form from './form.jsx'
import Checkout from './checkout.jsx'
import Postorder from './postorder.jsx'
import PrintPage from './print_page.jsx'
import PrintInfo from './print_info.jsx'
import Cart from './cart.jsx'
import Navbar from './navbar.jsx'
import NavbarBurger from './navbar_burger.jsx'
import Footer from './footer.jsx'

function App() {
  let location = useLocation()

  let makelist = (pages, props) => {
    return pages.map(page => {
      let class_name = 'navbar-link'
      if(props.page === page.path)
        class_name = 'navbar-selected'

      return (<Link key={page.path} className={class_name} to={page.path}>{page.text}</Link>)
    })
  }

  let makeNavList = (props) => {
    let pages = [
      { text: "Gallery", path: "/gallery" },
      { text: "Artist", path: "/artist" },
      { text: "Commisions", path: "/commisions" },
    ]

    if(props != undefined)
      return makelist(pages, props)
    else
      return ''
  }

  let makeFooterList = (props) => {
    let pages = [
      { text: "Home", path: "/" },
      { text: "Gallery", path: "/gallery" },
      { text: "Artist", path: "/artist" },
      { text: "Commisions", path: "/commisions" },
    ]

    if(props != undefined)
      return makelist(pages, props)
    else
      return ''
  }


  let [print_page_id, set_print_page_id] = useState(1)
  let [print_info_render, set_print_info_render] = useState('')
  let [navbar, setNavbar] = useState((<React.Fragment>
    <Navbar page={location.pathname} makeNavList={makeNavList} />
  </React.Fragment>))

  let cart = JSON.parse(localStorage.getItem('cart'))
  if(cart === null || !('push' in cart)) {
    localStorage.removeItem('cart')
    localStorage.setItem('cart', JSON.stringify([]))
  }

  let resize = () => {
    if(window.innerWidth > 500) {
      setNavbar((<React.Fragment>
        <Navbar page={location.pathname} makeNavList={makeNavList} />
      </React.Fragment>))
    } else {
      setNavbar((<React.Fragment>
        <NavbarBurger page={location.pathname} makeNavList={makeNavList} />
      </React.Fragment>))
    }
  }

  useEffect(resize , [location])

  const handle_focus_cancel = () => {
    set_print_info_render('')
    enableBodyScroll(document.body)
  }

  const handle_product_click = (id) => {
  disableBodyScroll(document.body)
    set_print_page_id(id)
    set_print_info_render(
      <div onClick={handle_focus_cancel} className='cover'>
        <span className='focus'>
          <PrintInfo product_id={id} />
        </span>
      </div>
    )
  }


  return (
    <div>
      {navbar}
      <div className='content'>
        <Routes>
          <Route path="/" element={<LandingPage handle_product_click={handle_product_click}/>} />
          <Route path="/cart" element={<Cart handle_product_click={handle_product_click}/>} />
          <Route path="/gallery" element={<Gallary handle_product_click={handle_product_click}/>} />
          <Route path="/artist" element={<AboutPage handle_product_click={handle_product_click}/>} />
          <Route path="/commisions" element={<Commisions handle_product_click={handle_product_click}/>} />
          <Route path="/form" element={<Form />} />
          <Route path="/postorder" element={<Postorder />} />
          <Route path="/checkout" element={<Checkout handle_product_click={handle_product_click}/>} />
          <Route path="/print-page" element={<PrintPage print_id={print_page_id} />} />
        </Routes>
      </div>
        {print_info_render}
      <Footer makeNavList={makeFooterList} page={location.pathname} />
    </div>
  );
}


      // <Navbar handle_product_click={handle_product_click} page={location.pathname}/>

export default App
