import RandomGallary from './random_gallary.jsx'
import { useState } from 'react';
import PrintInfo from './print_info.jsx'
import './print_page.css'

function PrintPage({print_id}) {
  let [product_id, set_product_id] = useState(print_id)

  const handle_click = (e) => {
    set_product_id(parseInt(e.target.id))
  }

  const [update, set_update] = useState('')

  return (
    <div>
      <PrintInfo product_id={product_id}/>
      <div>
        <RandomGallary handle_click={handle_click} on_update={set_update} />
      </div>
    </div>
  )
}

export default PrintPage
