import { Component } from 'react'
import get_env from './env.jsx'

class Postorder extends Component {
  constructor() {
    super()
    this.state = {
      result: ''
    }
  }

  componentDidMount() {
    const env = get_env()
    const query_string = window.location.search
    const url_params = new URLSearchParams(query_string)
    const order_id = url_params.get('order_id')
    const result_id = url_params.get('result_id')

    fetch(env.BACK_END_URL + "/get-order-result", {
      method: "POST",
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        order_id: order_id,
        result_id: result_id
      }),
    })
      .then(res => {
        if(res.ok)
          return res.json()
        else
          return res.json().then(json => Promise.reject(json))

      })
      .then(({ result }) => {
        if(result) {
          this.setState({
            result: "Thank you for your purchase"
          })
        } else {
          this.setState({
            result: "The order failed"
          })
        }
      })
      .catch(e => {
        console.error(e.error)
      })

  }


  render() {
    return (
      <div>
        <h1>{this.state.result}</h1>
      </div>
    )
  }
}

// function Postorder() {
//   const query_string = window.location.search
//   const url_params = new URLSearchParams(query_string)
//   const order_id = url_params.get('order_id')
//   const result_id = url_params.get('result_id')
//   const [ result, set_result ] = useState('')
//
//   async function get_result() {
//     let is_success = false;
//     fetch("http://localhost:3000/get-order-result", {
//       method: "POST",
//       headers: {
//         'Content-Type': 'application/json'
//       },
//       body: JSON.stringify({
//         order_id: order_id,
//         result_id: result_id
//       }),
//     })
//       .then(res => {
//         if(res.ok)
//           return res.json()
//         else
//           return res.json().then(json => Promise.reject(json))
//
//       })
//       .then(({ result }) => {
//         is_success = result
//       })
//       .catch(e => {
//         console.error(e.error)
//       })
//
//     if(is_success) {
//       set_result("Thank you for your purchase")
//     } else {
//       set_result("The order failed")
//     }
//   }
//
//   return (
//     <div>
//       <h1>{result}</h1>
//     </div>
//   )
// }

export default Postorder
