import gallary_data from './commisions.json';
import './commisions.css'
const data = gallary_data

function CommisionsOld() {
  return (
    <div className='commisions_content'>
      {data.items.map((item) => {
        return (
          <div className="commisions_item" key={item.id}>
            <span className="commisions_item_img_link">
              <img className="commisions_item_img" src={'assets/images/commisions/' + item.picture} ></img>
            </span>
          </div>
        );
      })}
    </div>
  )
}

export default CommisionsOld
